.rangeslider__handle {
    width: 20px !important;
    height: 20px !important;
    border-radius: 50% !important;
    background-color: #fff !important;
    box-shadow: 0 1px 1px #333 !important;
}

.rangeslider-horizontal .rangeslider__handle::after {
    display: none;
}

.rangeslider__labels {
    bottom: 15px;
    color: black !important;
}


@media (max-width: 375px) {
    .scale-slider {
        width: 62%;
    }

}

.scale-container {
    display: flex;
    flex-direction: column;
}

.scale-slider-wrapper {
    display: flex;
}

.scale-label {
    position: relative;
    top: 16px;
}

.scale-slider {
    width: 30%;
    margin-right: 5%;
    margin-bottom: 4%;
}

.rangeslider__fill {
    background-color: #255569 !important;
    /*background: linear-gradient(42deg, #255569, #a24372) !important;*/
}