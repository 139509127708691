i.icon {
    font-family: "Font Awesome 5 Pro";
}

i.icon.solid {
    font-weight: 900;
}

i.icon.light {
    font-weight: 300;
}

html, body {
    background: #f6f6f6;
    font-family: Lato;
}

.color-purple {
    color: purple !important;
}

.color-teal {
    color: teal;
}

.color-black {
    color: black;
}

.required {
    color: #AF0000;
}

.button-error {
    background-color: #e7bdbc !important;
}