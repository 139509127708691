#home {
    display: flex;
    flex-direction: column;
    align-items: center;
}
#home .login {
    width: 90%;
    max-width: 500px;
    margin: 5em 5% 0 5%;
}
#home .login-input {
    max-width: 200px;
}
#home .login-controls {
    display: flex;
    flex-direction: row-reverse;
}
