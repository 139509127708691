.menubar {
    background: linear-gradient(42deg, #255569, #a24372) !important; /* override .ui.inverted.menu */
    box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15) !important; /* override .ui.inverted.menu */
    font-size: 13px !important;
    height: 4em;
    display: flex;
    align-items: center;
}

.menubar-logo {
    width: 130px;
    margin: 1em;
}
